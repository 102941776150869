import { createSlice , PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    value : {}
}

export const resumeSlice = createSlice({
  name: '@@resume',
  initialState,   
  reducers: {
    setResume: (state , action : PayloadAction<any>) => {

      console.log(state.value , "dipatch action value");
      console.log(action.payload , "dispatch payload value");
      state.value = action.payload
    }
  }
})

export const { setResume } = resumeSlice.actions

export const resumeReducer =  resumeSlice.reducer