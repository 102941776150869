import React, { ReactNode, SetStateAction } from 'react';
import { useAppDispatch } from '@msanvarov/store';
import classNames from 'classnames';
import { Head } from '../head.component';
import { Breadcrumb } from './breadcrumb.component';
import { Footer } from './footer.component';
import { Header } from './header.component';
import { MobileBottomNav } from './bottomnav.component.jsx';
import InfoBox from './infobox'; // Import the InfoBox component

type LayoutProps = {
  title?: string;
  breadcrumb?: string;
  heading?: string;
  wrapperClass?: string;
  children: ReactNode;
  head?: ReactNode;
  setShowSignup?: React.Dispatch<SetStateAction<boolean>>;
  isSidebarCollapsed?: boolean;
};

export const Layout = ({
  title,
  breadcrumb,
  heading,
  wrapperClass,
  head,
  children,
  setShowSignup,
  isSidebarCollapsed,
}: LayoutProps) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <Head {...{ title }}>{head}</Head>
      <InfoBox /> {/* Add InfoBox component here */}
      <section
        className={classNames(wrapperClass ? wrapperClass : 'main-homepage')}
      >
        <Header
          setShowSignup={setShowSignup}
          isSidebarCollapsed={isSidebarCollapsed}
        />
        {breadcrumb && <Breadcrumb {...{ breadcrumb, heading }} />}
        {children}
      </section>
      <MobileBottomNav />
      <Footer />
    </>
  );
};

export default Layout;
