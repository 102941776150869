import { Preloader } from '@msanvarov/core-components';
import { persistor, store } from '@msanvarov/store';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AnimatePresence } from 'framer-motion';
import { ThemeProvider } from 'next-themes';
import { AppProps } from 'next/app';
import { Lato } from 'next/font/google';
import Head from 'next/head';
import Router from 'next/router';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {
  registerDebugBearRUM,
  registerGTM,
  registerHotjar,
  registerMicrosoftClarity,
  registerVercelAnalytics,
} from '../utils/tracking.utils';
import './styles-light.scss';
import './styles.scss';
import {
  ClerkProvider,
  SignInButton,
  SignedIn,
  SignedOut,
  UserButton,
} from '@clerk/nextjs';
import GoogleAnalytics from '../../../GoogleAnalytics';

const font = Lato({
  weight: ['100', '300', '400', '700', '900'],
  subsets: ['latin'],
  display: 'swap',
  preload: true,
});



const CustomApp = ({ Component, pageProps }: AppProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    AOS.init();

    registerHotjar();
  }, []);

  useEffect(() => {
    // Page transition
    const start = () => {
      setLoading(true);
    };
    const end = () => {
      setTimeout(() => setLoading(false), 250);
    };
    Router.events.on('routeChangeStart', start);
    Router.events.on('routeChangeComplete', end);
    Router.events.on('routeChangeError', end);

    return () => {
      Router.events.off('routeChangeStart', start);
      Router.events.off('routeChangeComplete', end);
      Router.events.off('routeChangeError', end);
    };
  }, [Router]);
  

  return (
    <ClerkProvider {...pageProps}>
      <Provider {...{ store }}>
        <PersistGate loading={<Preloader />} {...{ persistor }}>
          <ThemeProvider
            {...{
              defaultTheme: 'light',
            }}
          >
            <Head>
              <title>AirEdify | Education in Air</title>
              <meta name="title" content="AirEdify" />
              <meta
                name="description"
                content="AirEdify - Education in Air. We are group of experienced Designers and Developers who are trying to make education open source to everyone."
              />
              <meta name="keywords" content="masterclass, learning, events, jobs" />
              <meta
                name="google-adsense-account"
                content="ca-pub-9965905819710501"
              />

              {/* <!-- Open Graph / Facebook --> */}
              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://www.airedify.in/" />
              <meta property="og:title" content="AirEdify" />
              <meta
                property="og:description"
                content="AirEdify - Education in Air. We are group of experienced Designers and Developers who are trying to make education open source to everyone."
              />
              <meta
                property="og:image"
                content="https://www.sal-anvarov.com/assets/3d-headshot.png"
              />

              {/* <!-- Twitter --> */}
              <meta property="twitter:card" content="summary_large_image" />
              <meta property="twitter:url" content="https://www.airedify.in/" />
              <meta property="twitter:title" content="AirEdify" />
              <meta
                property="twitter:description"
                content="AirEdify - Education in Air. We are group of experienced Designers and Developers who are trying to make education open source to everyone."
              />
              <meta
                property="twitter:image"
                content="https://www.sal-anvarov.com/assets/3d-headshot.png"
              />

              

              <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/iconoir/6.9.0/css/iconoir.min.css"
              />
              <script
                src="//code.tidio.co/j8myzvefkb6hworpvg6y1qpmzk6faaj1.js"
                async
              ></script>

              <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9965905819710501"
              ></script>
              {registerDebugBearRUM()}
              {registerMicrosoftClarity()}
            </Head>
            <GoogleAnalytics />

            {registerGTM()}
            <AnimatePresence mode="wait" initial={false}>
              <main className={font.className}>
                {loading ? <Preloader /> : <Component {...pageProps} />}
                {registerVercelAnalytics()}
              </main>
            </AnimatePresence>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </ClerkProvider>
  );
};

export default CustomApp;
